<template>
  <v-card tile>
    <v-card-title class="pa-0">
      <v-toolbar color="#083272" dark class="">
        <v-toolbar-title>
          <h3 class="font-weight-bold suptitle">New Thread</h3>
        </v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-container>
        <v-row>
          <v-col>
            <tiptap-vuetify
              v-model="thread_contents"
              :extensions="extensions"
              :nativeExtensions="nativeExtensions"
              placeholder="Thread 대화 내용을 자세히 입력하십시오."
              :disableInputRules="true"
              :disablePasteRules="true"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto ml-auto">
            <v-btn color="success" @click="newThread">
              CREATE <v-icon>mdi-check</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { newThread } from "@/api/CaseThread";
import TipTapCustomImage from "@/components/TipTapExt/TipTapCustomImage";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
  // Image,
} from "@jwmsg/tiptap-vuetify";
export default {
  components: { TiptapVuetify },
  props: {
    refreshFunc: {
      type: Function,
    },
    successFunc: {
      type: Function,
    },
    caseUUID: {
      type: String,
    },
  },
  methods: {
    async newThread() {
      if (this.is_proceed == true) {
        return alert("처리중입니다. 잠시만 기다려주시기 바랍니다.");
      }
      this.is_proceed = true;
      const data = await newThread(this.caseUUID, this.thread_contents);
      if (data.success) {
        if (this.refreshFunc != null) await this.refreshFunc();
        if (this.successFunc != null) await this.successFunc();
      }
      this.thread_contents = null;
      this.is_proceed = false;
    },
  },
  data() {
    return {
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
        // Image,
      ],
      nativeExtensions: [new TipTapCustomImage()],
      thread_contents: null,
      is_proceed: false,
    };
  },
};
</script>

<style>
.tiptap-vuetify-editor .ProseMirror {
  min-height: 250px;
  max-height: 250px;
}
</style>
