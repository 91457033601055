<template>
  <v-card tile>
    <v-card-title class="pa-0">
      <v-container>
        <v-row style="background: #ccc">
          <v-col class="py-1">
            <p class="ma-0 pa-0 text-caption">
              {{
                threadData.TB_USER_ACCOUNT_LIST.TB_PRST_USER_LEVEL.level_name
              }}
            </p>
            <p class="ma-0 pa-0 text-body-2 font-weight-bold">
              {{ threadData.TB_USER_ACCOUNT_LIST.user_name_first_eng }}
              {{ threadData.TB_USER_ACCOUNT_LIST.user_name_last_eng }}
              ({{ threadData.TB_USER_ACCOUNT_LIST.user_id }})
            </p>
          </v-col>
          <v-col class="py-1">
            <p class="ma-0 pa-0 text-caption text-right">
              {{ createTime }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col class="px-0 pb-0">
            <div
              id="contentbox"
              v-html="threadData.thread_contents"
            ></div> </v-col></v-row
      ></v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  computed: {
    createTime() {
      return moment(this.threadData.createdAt).format("yyyy-MM-DD HH:mm:ss");
    },
  },
  props: {
    threadData: {
      type: Object,
      require: true,
    },
  },
};
</script>

<style>
#contentbox p:after {
  content: "";
  display: inline-block;
  width: 0px;
}
</style>
