<template>
  <v-container>
    <v-row>
      <v-fade-transition leave-absolute>
        <v-col v-if="currentCase != null">
          <h3>VIEW CASE [ {{ currentCase.case_id }} ]</h3>
        </v-col>
      </v-fade-transition>
    </v-row>
    <v-divider class="my-2"></v-divider>
    <v-row>
      <v-col cols="12">
        <CaseInfo
          :caseData="currentCase"
          :refreshFunc="getCase"
          :product_expired="product_expired"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CaseInfo from "@/components/Views/ViewCase/card/CaseInfo";
import { getCase } from "@/api/Case";
import * as moment from "moment";
export default {
  components: {
    CaseInfo,
  },
  props: {
    caseUUID: {
      type: String,
    },
  },

  beforeMount() {
    this.getCase();
  },

  methods: {
    async getCase() {
      const data = await getCase(this.caseUUID);
      if (data.success) {
        if (data.data.length > 0) this.currentCase = data.data[0];
      }
      if (this.currentCase.product_valid) {
        const today = moment();
        const enddate = moment(
          this.currentCase.product_valid.contr_prod_end_date
        );
        this.product_expired = enddate.diff(today, "d") < 0;
      } else {
        this.product_expired = true;
      }
    },
  },

  data() {
    return {
      currentCase: null,
      product_expired: false,
    };
  },
};
</script>
