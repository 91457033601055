import { requests } from "@/utils/requests";

export const changeCaseStatus = async (case_status, case_uuid) => {
  const data = await requests({
    url: `/api/service/cases/status/${case_uuid}`,
    method: "PUT",
    data: { case_status },
  });
  if (data.status == 200) return data.data;
  else return data;
};

export default {
  changeCaseStatus,
};
