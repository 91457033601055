<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-spacer></v-spacer>
      <v-col
        cols="auto"
        v-if="(caseData.case_status != 5 || threadOn) && !isReadOnly"
      >
        <v-btn color="#083272" dark @click="newThreadBtn" small>
          <v-icon>mdi-plus</v-icon>
          NEW THREAD
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        v-if="(caseData.case_status != 5 || threadOn) && isReadOnly"
      >
        <v-btn color="info" dark small>
          READONLY
          <v-icon class="ml-2">mdi-clock-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        v-for="threadData in threadList"
        :key="threadData.thread_uuid"
      >
        <v-fade-transition leave-absolute>
          <CaseThreadCard :threadData="threadData" v-if="threadData" />
        </v-fade-transition>
      </v-col>
    </v-row>
    <v-dialog v-model="showNewThread" width="800">
      <CreateCaseThread
        :caseUUID="caseUUID"
        :refreshFunc="getThread"
        :successFunc="closeNewThreadModal"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { getThread } from "@/api/CaseThread";
import CaseThreadCard from "@/components/Views/ViewCase/card/CaseThreadCard";
import CreateCaseThread from "@/components/Views/ViewCase/modal/CreateCaseThread";
import moment from "moment";
export default {
  components: { CaseThreadCard, CreateCaseThread },
  props: {
    caseUUID: {
      type: String,
    },
    caseData: {
      type: Object,
    },
  },
  computed: {
    myInfo() {
      return JSON.parse(this.$store.getters["userInfo/userData"]);
    },
    threadOn() {
      const changePermitLevel = [4, 5, 6, 7];
      const hasPermit = changePermitLevel.includes(this.myInfo.user_level);
      return hasPermit;
    },
    isAdmin() {
      return this.myInfo.user_level > 3;
    },
    isReadOnly() {
      const publicSetting = this.$store.getters["settings/publicSetting"];
      if (!publicSetting.READONLY_ON) return false;
      if (this.isAdmin) return false;
      const readonly_dow = publicSetting.READONLY_DOW.split(",");
      console.log();
      const readonly_end_time = moment(
        moment().format("yyyy-MM-DD") + " " + publicSetting.READONLY_END_TIME
      );
      const readonly_start_time = moment(
        moment().format("yyyy-MM-DD") + " " + publicSetting.READONLY_START_TIME
      );
      const today = moment();

      const is_inTime =
        readonly_end_time > today && readonly_start_time < today;
      const is_inDow = readonly_dow.find((e) => e == today.days()) != null;

      return !(is_inTime && is_inDow);
    },
  },
  mounted() {
    this.getThread();
  },
  methods: {
    async getThread() {
      const data = await getThread(this.caseUUID);
      if (data.success) {
        this.threadList = data.data;
      }
    },
    newThreadBtn() {
      this.showNewThread = true;
    },
    closeNewThreadModal() {
      this.showNewThread = false;
    },
  },
  data() {
    return { showNewThread: false, threadList: [] };
  },
};
</script>
