import { requests } from "@/utils/requests";

export const getThread = async (case_uuid) => {
  const data = await requests({
    url: `/api/service/thread/${case_uuid}`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const newThread = async (case_uuid, thread_contents) => {
  const data = await requests({
    url: `/api/service/thread/${case_uuid}`,
    method: "POST",
    data: { thread_contents },
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const putThread = async (case_uuid, thread_contents) => {
  const data = await putThread({
    url: `/api/service/thread/${case_uuid}`,
    method: "PUT",
    data: { thread_contents },
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const delThread = async (case_uuid) => {
  const data = await requests({
    url: `/api/service/thread/${case_uuid}`,
    method: "DELETE",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export default {
  getThread,
  newThread,
  putThread,
  delThread,
};
